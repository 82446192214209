import React, { useState } from 'react';
import { EmailLink } from '../components/ui/EmailLink';

export default function PrivacyPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <article className="prose prose-blue max-w-none">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
        <p className="text-gray-600 mb-8">
          <strong>Effective Date: January 22, 2025</strong>
        </p>

        <p className="text-gray-700 mb-8">
          Welcome to CampWhiz.com ("we," "our," "us"), owned and operated by Sampa360 LLC. 
          Your privacy is important to us, and we are committed to protecting the personal 
          information you share with us. This Privacy Policy explains how we collect, use, 
          and safeguard your information when you visit our website.
        </p>

        <hr className="my-8" />

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Information We Collect</h2>
          <p className="text-gray-700 mb-4">
            We collect information to provide you with the best possible experience on our platform. This includes:
          </p>
          <div className="ml-6">
            <h3 className="text-xl font-semibold text-gray-900 mb-2">1. Personal Information:</h3>
            <ul className="list-disc ml-6 text-gray-700 mb-4">
              <li>Name, email address, and contact details when you create an account or sign up for newsletters.</li>
              <li>Payment details when you purchase services through our platform.</li>
            </ul>

            <h3 className="text-xl font-semibold text-gray-900 mb-2">2. Non-Personal Information:</h3>
            <ul className="list-disc ml-6 text-gray-700 mb-4">
              <li>Browser type, operating system, IP address, and other technical details automatically collected through cookies and analytics tools.</li>
            </ul>

            <h3 className="text-xl font-semibold text-gray-900 mb-2">3. Activity Information:</h3>
            <ul className="list-disc ml-6 text-gray-700 mb-4">
              <li>Pages you visit, time spent on our site, and interactions with content.</li>
            </ul>
          </div>
        </section>

        <hr className="my-8" />

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">How We Use Your Information</h2>
          <p className="text-gray-700 mb-4">We use the collected information to:</p>
          <ul className="list-disc ml-6 text-gray-700 mb-4">
            <li>Provide and improve our services.</li>
            <li>Communicate with you about updates, promotions, or support inquiries.</li>
            <li>Process transactions securely.</li>
            <li>Analyze user behavior to enhance the website experience.</li>
            <li>Comply with legal obligations.</li>
          </ul>
        </section>

        <hr className="my-8" />

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Cookies and Tracking Technologies</h2>
          <p className="text-gray-700 mb-4">
            We use cookies, web beacons, and similar tracking technologies to enhance your browsing experience. 
            These help us understand how you interact with our site and deliver personalized content.
          </p>
          <p className="text-gray-700">
            You can manage your cookie preferences in your browser settings.
          </p>
        </section>

        <hr className="my-8" />

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">How We Share Your Information</h2>
          <p className="text-gray-700 mb-4">
            We do not sell or rent your personal information to third parties. However, we may share your information with:
          </p>
          <ul className="list-disc ml-6 text-gray-700 mb-4">
            <li>
              <strong>Service Providers:</strong> Trusted third parties that help us operate our website, 
              such as payment processors or email marketing tools.
            </li>
            <li>
              <strong>Legal Authorities:</strong> When required by law or to protect our legal rights.
            </li>
          </ul>
        </section>

        <hr className="my-8" />

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Third-Party Links</h2>
          <p className="text-gray-700">
            Our website may contain links to third-party websites. We are not responsible for the privacy 
            practices or content of those sites. We encourage you to read their privacy policies before 
            providing any personal information.
          </p>
        </section>

        <hr className="my-8" />

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Data Security</h2>
          <p className="text-gray-700">
            We implement industry-standard security measures to protect your information. However, no method 
            of transmission over the internet or electronic storage is 100% secure. We cannot guarantee 
            absolute security but are committed to doing our best to safeguard your data.
          </p>
        </section>

        <hr className="my-8" />

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Your Rights</h2>
          <p className="text-gray-700 mb-4">
            Depending on your location, you may have rights regarding your personal information, including:
          </p>
          <ul className="list-disc ml-6 text-gray-700 mb-4">
            <li>Accessing, correcting, or deleting your information.</li>
            <li>Opting out of marketing communications.</li>
          </ul>
          <p className="text-gray-700">
            To exercise these rights, please contact us at{' '}
            <EmailLink />
          </p>
        </section>

        <hr className="my-8" />

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Children's Privacy</h2>
          <p className="text-gray-700">
            CampWhiz.com is not intended for children under the age of 13. We do not knowingly collect 
            personal information from children without parental consent. If we discover we have collected 
            information from a child under 13, we will delete it promptly.
          </p>
        </section>

        <hr className="my-8" />

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Changes to This Policy</h2>
          <p className="text-gray-700">
            We may update this Privacy Policy from time to time. Any changes will be posted on this page 
            with the "Effective Date" updated. We encourage you to review this policy periodically.
          </p>
        </section>

        <hr className="my-8" />

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Contact Us</h2>
          <p className="text-gray-700 mb-4">
            If you have any questions or concerns about this Privacy Policy, please contact us:
          </p>
          <div className="bg-gray-50 p-6 rounded-lg">
            <p className="font-semibold text-gray-900 mb-2">Sampa360 LLC</p>
            <p className="text-gray-700 mb-1">
              <strong>Website:</strong>{' '}
              <a href="https://www.campwhiz.com" className="text-blue-600 hover:text-blue-800">
                www.campwhiz.com
              </a>
            </p>
            <p className="text-gray-700">
              <strong>Email:</strong>{' '}
              <EmailLink />
            </p>
          </div>
        </section>

        <p className="text-gray-700 text-center mt-12">
          Thank you for trusting CampWhiz.com!
        </p>
      </article>
    </div>
  );
} 
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { supabase } from './lib/supabase';
import { Toaster } from 'react-hot-toast';
import HomePage from './pages/HomePage';
import SignInPage from './pages/SignInPage';
import FavoriteCampsPage from './pages/FavoriteCampsPage';
import AdminPage from './pages/AdminPage';
import CalendarTeaser from './pages/CalendarTeaser';
import Calendar from './pages/Calendar';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { initializeGA, trackPageView } from './lib/analytics';
import { initializeMixpanel, trackMixpanelPageView } from './lib/mixpanel';
import { useEffect } from 'react';
import CookieConsent from './components/CookieConsent';
import PrivacyPage from './pages/PrivacyPage';

const queryClient = new QueryClient();

// Analytics wrapper component
function AnalyticsWrapper({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  useEffect(() => {
    try {
      trackPageView(location.pathname + location.search);
      trackMixpanelPageView(location.pathname + location.search);
    } catch (error) {
      console.warn('Failed to track page view:', error);
    }
  }, [location]);

  return <>{children}</>;
}

function App() {
  useEffect(() => {
    // Initialize analytics services
    try {
      initializeGA();
      initializeMixpanel();
    } catch (error) {
      console.warn('Failed to initialize analytics:', error);
    }
  }, []);

  return (
    <SessionContextProvider supabaseClient={supabase}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AnalyticsWrapper>
            <div className="min-h-screen flex flex-col">
              <Navigation />
              <main className="flex-1 bg-gradient-to-b from-blue-50/50 to-green-50/50">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/login" element={<SignInPage />} />
                  <Route path="/favorites" element={<FavoriteCampsPage />} />
                  <Route path="/admin" element={<AdminPage />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/calendar-preview" element={<CalendarTeaser />} />
                  <Route path="/privacy" element={<PrivacyPage />} />
                </Routes>
              </main>
              <Footer />
              <CookieConsent />
              <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                toastOptions={{
                  duration: 5000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },
                }}
              />
            </div>
          </AnalyticsWrapper>
        </Router>
      </QueryClientProvider>
    </SessionContextProvider>
  );
}

export default App;
import { useNavigate } from 'react-router-dom';
import { CalendarDaysIcon, ClockIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { useSession } from '@supabase/auth-helpers-react';

export default function CalendarTeaser() {
  const navigate = useNavigate();
  const session = useSession();

  const benefits = [
    {
      title: "Time-Saving Organization",
      description: "Effortlessly manage all your children's camp schedules in one place. No more juggling multiple calendars or missing important dates.",
      icon: ClockIcon
    },
    {
      title: "Family Calendar Sync",
      description: "Share camp schedules with family members and caregivers. Everyone stays informed and coordinated with real-time updates.",
      icon: CalendarDaysIcon
    },
    {
      title: "Child Activity Tracking",
      description: "Keep track of where each child is at any time. Get peace of mind with a clear overview of all camp activities and locations.",
      icon: UserGroupIcon
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50/50 to-green-50/50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Simplify Your Summer Camp Planning
          </h1>
          <p className="text-xl text-gray-500 mb-8 max-w-2xl mx-auto">
            Our smart calendar system helps you organize and track all your children's summer camp activities in one place.
          </p>

          <button
            onClick={() => navigate(session ? '/calendar' : '/login')}
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {session ? 'Open Calendar' : 'Register Now'}
          </button>
        </div>

        <div className="mt-16">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
            {benefits.map((benefit) => (
              <div
                key={benefit.title}
                className="relative bg-white p-6 rounded-lg shadow-md"
              >
                <div className="absolute top-6 left-6 bg-blue-100 rounded-lg p-3">
                  <benefit.icon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                </div>
                <div className="ml-16">
                  <h3 className="text-lg font-medium text-gray-900 mb-2">{benefit.title}</h3>
                  <p className="text-gray-500">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-16 bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Calendar Preview</h2>
            <div className="aspect-w-16 aspect-h-9 bg-gray-100 rounded-lg">
              <img
                src="https://placehold.co/1200x675/e2e8f0/475569?text=Calendar+Interface+Preview"
                alt="Calendar interface preview"
                className="object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

export default function Navigation() {
  const session = useSession();
  const supabase = useSupabaseClient();
  const location = useLocation();
  const navigate = useNavigate();

  // Check admin status using RPC function
  const { data: isAdmin = false } = useQuery(
    ['is-admin', session?.user?.id],
    async () => {
      if (!session?.user?.id) return false;
      
      const { data, error } = await supabase
        .rpc('check_is_admin', {
          user_id: session.user.id
        });
      
      if (error) {
        console.error('Error checking admin status:', error);
        return false;
      }
      
      return !!data;
    },
    {
      enabled: !!session?.user?.id,
      staleTime: 30000, // Cache for 30 seconds
      cacheTime: 60000, // Keep in cache for 1 minute
      retry: false
    }
  );

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      navigate('/');
      toast.success('Successfully signed out');
    } catch (err) {
      console.error('Error signing out:', err);
      toast.error('Error signing out');
    }
  };

  const getLinkClasses = (path: string) => {
    const isActive = location.pathname === path;
    return `text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium relative ${
      isActive ? 'after:absolute after:bottom-0 after:left-0 after:right-0 after:h-0.5 after:bg-fuchsia-500' : ''
    }`;
  };

  return (
    <nav className="bg-white shadow-md relative z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <span className="text-xl font-bold text-blue-600">CampWhiz.com</span>
            </Link>
          </div>
          
          <div className="flex items-center space-x-4">
            <Link 
              to="/"
              className={getLinkClasses('/')}
            >
              Find Camps
            </Link>
            {session ? (
              <>
                <Link 
                  to="/favorites" 
                  className={getLinkClasses('/favorites')}
                >
                  My Favorites
                </Link>
                <Link 
                  to="/calendar" 
                  className={getLinkClasses('/calendar')}
                >
                  Calendar
                </Link>
                {isAdmin && (
                  <Link 
                    to="/admin" 
                    className="border-2 border-blue-500 text-blue-500 px-4 py-1.5 rounded-md text-sm font-medium hover:bg-blue-50 transition-colors"
                  >
                    Admin
                  </Link>
                )}
                <button
                  onClick={handleSignOut}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-600"
                >
                  Sign Out
                </button>
              </>
            ) : (
              <>
                <Link 
                  to="/calendar-preview" 
                  className={getLinkClasses('/calendar-preview')}
                >
                  Calendar
                </Link>
                <Link 
                  to="/login" 
                  className="bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-600"
                >
                  Sign In / Register
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
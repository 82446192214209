import { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useSession } from '@supabase/auth-helpers-react';
import { supabase } from '../lib/supabase';
import type { CookieConsent as CookieConsentType } from '../types/database';

export default function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);
  const session = useSession();

  useEffect(() => {
    async function checkConsent() {
      if (!session?.user?.id) {
        setIsVisible(true);
        return;
      }

      const { data: consent, error } = await supabase
        .from('cookie_consents')
        .select('*')
        .eq('user_id', session.user.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (error || !consent) {
        setIsVisible(true);
        return;
      }

      // Check if consent has expired
      if (new Date(consent.expires_at) < new Date()) {
        setIsVisible(true);
        return;
      }

      setIsVisible(false);
    }

    void checkConsent();
  }, [session?.user?.id]);

  const handleAccept = async () => {
    if (!session?.user?.id) {
      setIsVisible(false);
      return;
    }

    const expires = new Date();
    expires.setMonth(expires.getMonth() + 6);

    try {
      await supabase
        .from('cookie_consents')
        .insert({
          user_id: session.user.id,
          accepted: true,
          expires_at: expires.toISOString()
        });

      setIsVisible(false);
    } catch (error) {
      console.error('Error saving cookie consent:', error);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg transform transition-transform duration-500 ease-in-out z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between gap-4">
          <div className="flex-1">
            <p className="text-sm text-gray-700">
              We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.{' '}
              <a href="/privacy" className="text-blue-600 hover:text-blue-800 underline">
                Learn more
              </a>
            </p>
          </div>
          <div className="flex items-center gap-4 flex-shrink-0">
            <button
              onClick={handleAccept}
              className="bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Accept
            </button>
            <button
              onClick={() => setIsVisible(false)}
              className="text-gray-400 hover:text-gray-500"
              aria-label="Close"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export async function resetCookieConsent(userId: string) {
  if (!userId) return;
  
  await supabase
    .from('cookie_consents')
    .delete()
    .eq('user_id', userId);
} 
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '../lib/api';
import CampCard from '../components/CampCard';
import CampDetailsOverlay from '../components/CampDetailsOverlay';
import type { Camp } from '../types/database';
import toast from 'react-hot-toast';
import { useSession } from '@supabase/auth-helpers-react';
import { useNavigate } from 'react-router-dom';

export default function FavoriteCampsPage() {
  const [selectedCamp, setSelectedCamp] = useState<Camp | null>(null);
  const session = useSession();
  const navigate = useNavigate();

  const { data: camps = [], refetch } = useQuery(
    ['favorite-camps'],
    () => api.camps.getFavorites(),
    {
      enabled: !!session,
      onError: (error) => {
        console.error('Error fetching favorites:', error);
        toast.error('Failed to load favorite camps');
      }
    }
  );

  if (!session) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Please sign in to view your favorite camps</h1>
          <button
            onClick={() => navigate('/login')}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Sign In
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">My Favorite Camps</h1>

        {camps.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500 mb-4">You haven't saved any camps yet.</p>
            <button
              onClick={() => navigate('/')}
              className="text-blue-500 hover:text-blue-600"
            >
              Browse Camps
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {camps.map((camp) => (
              <CampCard
                key={camp.id}
                camp={camp}
                onViewDetails={() => setSelectedCamp(camp)}
                onFavoriteChange={refetch}
              />
            ))}
          </div>
        )}

        <CampDetailsOverlay
          camp={selectedCamp}
          isOpen={!!selectedCamp}
          onClose={() => setSelectedCamp(null)}
        />
      </div>
    </div>
  );
}
import { useState, useEffect } from 'react';
import { HeartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid, PencilSquareIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useSession } from '@supabase/auth-helpers-react';
import type { Camp, CampFavorite } from '../types/database';
import { getCampImage } from '../lib/images';
import { api } from '../lib/api';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';
import { trackEvent } from '../lib/analytics';
import { trackMixpanelEvent } from '../lib/mixpanel';

interface CampCardProps {
  camp: Camp & { camp_favorites?: CampFavorite[] };
  onViewDetails: (camp: Camp) => void;
  onFavoriteChange?: () => void;
  onEdit?: (camp: Camp) => void;
}

export default function CampCard({ camp, onViewDetails, onFavoriteChange, onEdit }: CampCardProps) {
  const [heroImage, setHeroImage] = useState<string>('');
  const [logoImage, setLogoImage] = useState<string>('');
  const [isFavorite, setIsFavorite] = useState(Boolean(camp.camp_favorites && camp.camp_favorites.length > 0));
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const session = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    // Get the first photo for hero image
    const photo = camp.camp_images?.find(img => img.type === 'photo');
    const logo = camp.camp_images?.find(img => img.type === 'logo');
    
    if (photo) {
      setHeroImage(photo.url);
    } else {
      const defaultImage = getCampImage(camp);
      setHeroImage(defaultImage);
    }

    if (logo) {
      setLogoImage(logo.url);
    }
  }, [camp]);

  // Check if user is admin
  useEffect(() => {
    async function checkAdminStatus() {
      if (!session?.user?.id) return;
      
      try {
        const { data } = await supabase
          .from('admin_users')
          .select('id')
          .eq('id', session.user.id)
          .single();
        
        setIsAdmin(!!data);
      } catch {
        setIsAdmin(false);
      }
    }

    void checkAdminStatus();
  }, [session?.user?.id]);

  const handleFavoriteClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isUpdating) return;

    if (!session) {
      toast((t) => (
        <span className="flex items-center gap-2">
          <span className="whitespace-nowrap">Please sign in to save camps</span>
          <button
            onClick={() => {
              toast.dismiss(t.id);
              navigate('/login');
            }}
            className="whitespace-nowrap bg-blue-500 text-white text-sm px-2 py-1 rounded hover:bg-blue-600 transition-colors"
          >
            Sign In
          </button>
        </span>
      ), {
        duration: 5000,
      });
      return;
    }

    try {
      setIsUpdating(true);
      const isNowFavorite = await api.camps.toggleFavorite(camp.id);
      setIsFavorite(isNowFavorite);
      onFavoriteChange?.();
      toast.success(isNowFavorite ? 'Added to favorites' : 'Removed from favorites');
    } catch (error) {
      toast.error('Failed to update favorite status');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEdit?.(camp);
  };

  const handleViewDetails = () => {
    trackEvent('view_camp_details', {
      camp_id: camp.id,
      camp_name: camp.name
    });
    trackMixpanelEvent('View Camp Details', {
      camp_id: camp.id,
      camp_name: camp.name,
      camp_location: `${camp.city}, ${camp.state}`,
      camp_age_range: `${camp.min_age}-${camp.max_age}`
    });
    onViewDetails(camp);
  };

  return (
    <div 
      onClick={handleViewDetails}
      className="h-full bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-200 hover:scale-[1.02] hover:shadow-lg cursor-pointer relative flex flex-col"
    >
      <div className="h-48 relative">
        <img
          src={heroImage}
          alt={`${camp.name} activities in ${camp.zip_code}`}
          className="w-full h-full object-cover transition-transform duration-200 group-hover:scale-105"
        />
        {logoImage && (
          <div className="absolute top-4 left-4">
            <img
              src={logoImage}
              alt={`${camp.name} logo`}
              className="w-16 h-16 rounded-lg shadow-lg object-cover bg-white"
            />
          </div>
        )}
        <div className="absolute top-2 right-2 flex items-center gap-2">
          {isAdmin && (
            <button
              onClick={handleEditClick}
              className="p-2 rounded-full bg-white/10 backdrop-blur-sm transition-all duration-300 hover:bg-white/20"
              aria-label="Edit camp"
            >
              <PencilSquareIcon className="h-6 w-6 text-white stroke-[1.5]" style={{ filter: 'drop-shadow(0 0 1px rgba(0,0,0,0.3))' }} />
            </button>
          )}
          <button
            onClick={handleFavoriteClick}
            className="p-2 rounded-full bg-white/10 backdrop-blur-sm transition-all duration-300 hover:bg-white/20"
            aria-label={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
          >
            {isFavorite ? (
              <HeartIconSolid className="h-6 w-6 text-fuchsia-500 transition-colors duration-300" />
            ) : (
              <HeartIcon className="h-6 w-6 text-white stroke-[1.5] transition-colors duration-300 hover:text-fuchsia-500" style={{ filter: 'drop-shadow(0 0 1px rgba(0,0,0,0.3))' }} />
            )}
          </button>
        </div>
      </div>
      <div className="flex flex-col flex-grow p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{camp.name}</h3>
        <p className="text-gray-600 mb-4 line-clamp-3 flex-grow">{camp.description}</p>
        <div className="mt-auto">
          <div className="text-sm text-gray-500 mb-4">
            <p>{camp.city}, {camp.state}</p>
            <p>Ages {camp.min_age}-{camp.max_age}</p>
          </div>
          <div className="w-full bg-blue-500 text-white py-2 px-4 rounded text-center transition-colors hover:bg-blue-600">
            View Details
          </div>
        </div>
      </div>
    </div>
  );
}
// Default map center (approximately center of continental US)
export const DEFAULT_MAP_CENTER = {
  lat: 39.8283,
  lng: -98.5795,
};

// Default zoom levels
export const DEFAULT_MAP_ZOOM = 5; // Shows most of continental US
export const LOCATION_ZOOM = 10; // Zoom level for 30-mile radius view

// Search placeholder text
export const SEARCH_PLACEHOLDER = "Search by city, state, or camp name..."; 
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface AddToCalendarModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (campId: string, startDate: string, endDate: string) => Promise<void>;
  childId: string;
  childName: string;
}

interface Camp {
  id: string;
  name: string;
  description?: string;
}

export default function AddToCalendarModal({
  isOpen,
  onClose,
  onAdd,
  childId,
  childName
}: AddToCalendarModalProps) {
  const [selectedCamp, setSelectedCamp] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // Fetch children
  const { data: children = [] } = useQuery(
    ['children'],
    async () => {
      const { data, error } = await supabase
        .from('children')
        .select('*')
        .order('name');
      
      if (error) throw error;
      return data;
    }
  );

  // Add this query to fetch all camps
  const { data: camps = [] } = useQuery<Camp[]>(
    ['camps'],
    async () => {
      const { data, error } = await supabase
        .from('camps')
        .select('*')
        .order('name');
      
      if (error) throw error;
      return data;
    }
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedCamp || !startDate || !endDate) return;

    try {
      await onAdd(selectedCamp, startDate, endDate);
      // Reset form
      setSelectedCamp('');
      setStartDate('');
      setEndDate('');
    } catch (error) {
      console.error('Error in AddToCalendarModal:', error);
    }
  };

  const handleAddChild = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
    navigate('/calendar');
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    setSelectedCamp(e.target.value);
  };

  console.log('Camp Name in Modal:', childName);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog 
        as="div" 
        className="relative z-[9999]" 
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div 
            className="fixed inset-0 bg-gray-500/75 backdrop-blur-sm transition-opacity"
            onClick={(e) => e.stopPropagation()}
          />
        </Transition.Child>

        <div 
          className="fixed inset-0 z-10 overflow-y-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <div 
            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
            onClick={(e) => e.stopPropagation()}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel 
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                onClick={(e) => e.stopPropagation()}
              >
                <form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="absolute right-0 top-0 pr-4 pt-4">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <CalendarDaysIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Add {childName} to Calendar
                        </Dialog.Title>
                      </div>
                    </div>

                    <div className="mt-4 space-y-4">
                      {children.length === 0 ? (
                        <div className="text-center">
                          <p className="text-sm text-gray-500 mb-4">No children added yet</p>
                          <button
                            type="button"
                            onClick={handleAddChild}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                          >
                            Add Child Profile
                          </button>
                        </div>
                      ) : (
                        <>
                          <div onClick={(e) => e.stopPropagation()}>
                            <label htmlFor="camp" className="block text-sm font-medium text-gray-700">
                              Select Camp
                            </label>
                            <select
                              id="camp"
                              value={selectedCamp}
                              onChange={handleSelectChange}
                              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                              required
                              onClick={(e) => e.stopPropagation()}
                            >
                              <option value="">Select a camp</option>
                              {camps.map((camp) => (
                                <option key={camp.id} value={camp.id}>
                                  {camp.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="flex space-x-4">
                            <div className="flex-1">
                              <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">
                                Start Date
                              </label>
                              <input
                                type="date"
                                id="start-date"
                                value={startDate}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  setStartDate(e.target.value);
                                }}
                                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                required
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>

                            <div className="flex-1">
                              <label htmlFor="end-date" className="block text-sm font-medium text-gray-700">
                                End Date
                              </label>
                              <input
                                type="date"
                                id="end-date"
                                value={endDate}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  setEndDate(e.target.value);
                                }}
                                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                required
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {children.length > 0 && (
                    <div 
                      className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {isSubmitting ? 'Adding...' : 'Add to Calendar'}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
import { createClient } from '@supabase/supabase-js';

// Ensure these are defined in your .env file
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Create a single client instance
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  }
});

// Add authentication state listener
supabase.auth.onAuthStateChange((event, session) => {
  console.log('Auth state changed:', event, session?.user?.id);
});

// Add this type for Google user metadata
interface GoogleUserMetadata {
  avatar_url: string;
  email: string;
  email_verified: boolean;
  full_name: string;
  iss: string;
  name: string;
  picture: string;
  provider_id: string;
  sub: string;
}

// Add a helper function to get user profile
export async function getUserProfile() {
  const { data: { user } } = await supabase.auth.getUser();
  
  if (!user) return null;

  // Get the metadata which includes Google profile info
  const metadata = user.user_metadata as GoogleUserMetadata;
  
  // Log to verify we're getting the data
  console.log('User Metadata:', metadata);

  return {
    id: user.id,
    email: user.email,
    fullName: metadata.full_name,
    avatarUrl: metadata.avatar_url,
    // Add any other fields you need
  };
}
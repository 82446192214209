import mixpanel from 'mixpanel-browser';

let isInitialized = false;

// Initialize Mixpanel
export const initializeMixpanel = () => {
  if (!isInitialized && import.meta.env.VITE_MIXPANEL_TOKEN) {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      debug: import.meta.env.DEV,
      track_pageview: true,
      persistence: 'localStorage'
    });
    isInitialized = true;
  }
};

// Track page views
export const trackMixpanelPageView = (path: string) => {
  if (!isInitialized) return;
  
  try {
    mixpanel.track('Page View', {
      path,
      title: document.title,
      url: window.location.href
    });
  } catch (error) {
    console.warn('Failed to track page view:', error);
  }
};

// Track events
export const trackMixpanelEvent = (eventName: string, properties = {}) => {
  if (!isInitialized) return;

  try {
    mixpanel.track(eventName, {
      ...properties,
      timestamp: new Date().toISOString()
    });
  } catch (error) {
    console.warn('Failed to track event:', error);
  }
};

// Identify users
export const identifyMixpanelUser = (userId: string, properties = {}) => {
  if (!isInitialized) return;

  try {
    mixpanel.identify(userId);
    mixpanel.people.set({
      ...properties,
      $last_seen: new Date().toISOString()
    });
  } catch (error) {
    console.warn('Failed to identify user:', error);
  }
}; 
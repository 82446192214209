import { useState, Fragment, useEffect } from 'react';
import { useSession } from '@supabase/auth-helpers-react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon, XMarkIcon, ExclamationTriangleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Dialog, Transition } from '@headlessui/react';
import { supabase } from '../lib/supabase';
import AddChildModal from '../components/AddChildModal';
import CalendarExport from '../components/CalendarExport';
import toast from 'react-hot-toast';
import AddToCalendarModal from '../components/AddToCalendarModal';
import { Tooltip } from '../components/ui/Tooltip';

interface Child {
  id: string;
  name: string;
  age: number;
  camp_schedules?: CampSchedule[];
}

interface CampSchedule {
  id: string;
  camp_id: string;
  start_date: string;
  end_date: string;
  camp?: {
    id: string;
    name: string;
    description?: string;
    address?: string;
    city?: string;
    state?: string;
    zip_code?: string;
  };
}

interface DeleteConfirmation {
  scheduleId: string;
  childName: string;
  campName: string;
}

// Add type for the child form data
interface ChildFormData {
  id: string;
  name: string;
  age: number;
}

// Add type for API error
interface ApiError {
  message: string;
}

// Add this type guard function
const ensureString = (value: string | null): string => {
  return value ?? '';  // Returns empty string if value is null
};

export default function Calendar(): JSX.Element {
  const session = useSession();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  
  // Simplify state management
  const [isAddChildModalOpen, setIsAddChildModalOpen] = useState(false);
  const [isAddScheduleModalOpen, setIsAddScheduleModalOpen] = useState(false);
  const [selectedChildId, setSelectedChildId] = useState<string | null>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<DeleteConfirmation | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedChild, setSelectedChild] = useState<ChildFormData | null>(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{ type: 'child' | 'camp'; id: string } | null>(null);

  // Modify query to match favorites pattern
  const { 
    data: children = [], 
    error: childrenError, 
    isLoading,
    isFetching,
    refetch 
  } = useQuery<Child[], ApiError>(
    ['children', session?.user?.id], // Add user ID to query key
    async () => {
      if (!session) throw new Error('Not authenticated');

      const { data, error } = await supabase
        .from('children')
        .select(`
          *,
          camp_schedules (
            id,
            camp_id,
            start_date,
            end_date,
            camp:camps (
              id,
              name,
              description,
              address,
              city,
              state,
              zip_code
            )
          )
        `)
        .eq('user_id', session.user.id)
        .order('created_at');

      if (error) throw error;
      
      console.log('Raw Database Response:', JSON.stringify(data, null, 2));
      
      return data || [];
    },
    {
      enabled: !!session,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: 1000 * 60, // 1 minute
      cacheTime: 1000 * 60 * 5, // 5 minutes
      retry: 2,
      onSuccess: (data) => {
        console.log('Query succeeded:', data);
      },
      onError: (error) => {
        console.error('Query failed:', error);
      }
    }
  );

  if (!session) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            Please sign in to view your calendar
          </h1>
          <button
            onClick={() => navigate('/login')}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Sign In
          </button>
        </div>
      </div>
    );
  }

  // Show loading state only on initial load
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Loading your children's schedules...</p>
      </div>
    );
  }

  const handleAddSchedule = async (campId: string, startDate: string, endDate: string) => {
    if (!selectedChildId) return;

    try {
      const { error: insertError } = await supabase
        .from('camp_schedules')
        .insert({
          child_id: selectedChildId,
          camp_id: campId,
          start_date: startDate,
          end_date: endDate
        })
        .select(`
          id,
          camp_id,
          start_date,
          end_date,
          camp:camps!inner (
            id,
            name,
            description,
            address,
            city,
            state,
            zip_code
          )
        `); // Add select to get the full data

      if (insertError) throw insertError;

      // Immediately invalidate and refetch
      await Promise.all([
        queryClient.invalidateQueries(['children', session?.user?.id]),
        refetch()
      ]);

      toast.success('Schedule added successfully', {
        position: 'bottom-center'
      });
      setIsAddScheduleModalOpen(false);
      setSelectedChildId(null);
    } catch (error) {
      console.error('Error adding schedule:', error);
      toast.error('Failed to add schedule', {
        position: 'bottom-center'
      });
    }
  };

  const handleRemoveSchedule = async () => {
    if (!deleteConfirmation) return;

    try {
      const { error } = await supabase
        .from('camp_schedules')
        .delete()
        .eq('id', deleteConfirmation.scheduleId);

      if (error) throw error;

      await queryClient.invalidateQueries(['children', session?.user?.id]);
      await refetch();

      toast.success('Schedule removed successfully');
    } catch (error) {
      console.error('Error removing schedule:', error);
      toast.error('Failed to remove schedule');
    } finally {
      setDeleteConfirmation(null);
    }
  };

  const handleEditChild = async () => {
    if (!selectedChild || !selectedChild.id) return;

    try {
      const { error } = await supabase
        .from('children')
        .update({ name: selectedChild.name, age: selectedChild.age })
        .eq('id', selectedChild.id);

      if (error) throw error;

      toast.success('Child updated successfully');
      setIsEditModalOpen(false);
      queryClient.invalidateQueries(['children', session?.user?.id]);
    } catch (error) {
      console.error('Error updating child:', error);
      toast.error('Failed to update child');
    }
  };

  const handleDeleteChild = async (id: string) => {
    try {
      const { error } = await supabase
        .from('children')
        .delete()
        .eq('id', id);

      if (error) throw error;

      toast.success('Child deleted successfully');
      queryClient.invalidateQueries(['children', session?.user?.id]);
    } catch (error) {
      console.error('Error deleting child:', error);
      toast.error('Failed to delete child');
    }
  };

  const handleDeleteCamp = (id: string) => {
    setItemToDelete({ type: 'camp', id });
    setIsDeleteConfirmationOpen(true);
  };

  const handleAddChild = async (name: string, age: number) => {
    try {
      const { data: newChild, error } = await supabase
        .from('children')
        .insert({
          name,
          age,
          user_id: session?.user?.id
        })
        .select(`
          *,
          camp_schedules (
            id,
            camp_id,
            start_date,
            end_date,
            camp:camps (
              id,
              name,
              description,
              address,
              city,
              state,
              zip_code
            )
          )
        `)
        .single();

      if (error) throw error;

      // Immediately invalidate and refetch
      await Promise.all([
        queryClient.invalidateQueries(['children', session?.user?.id]),
        refetch()
      ]);

      toast.success('Child added successfully', {
        position: 'bottom-center'
      });
      setIsAddChildModalOpen(false);
    } catch (error) {
      console.error('Error adding child:', error);
      toast.error('Failed to add child', {
        position: 'bottom-center'
      });
    }
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-blue-50/50 to-green-50/50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-900">{new Date().getFullYear()} Camp Schedule</h1>
          <Tooltip 
            content="Maximum of 5 children allowed"
            enabled={children.length >= 5}
          >
            <button
              onClick={() => setIsAddChildModalOpen(true)}
              className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                ${children.length >= 5 
                  ? 'bg-gray-400 cursor-not-allowed' 
                  : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                }`}
              disabled={children.length >= 5}
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Add Child
            </button>
          </Tooltip>
        </div>

        {childrenError && (
          <p className="text-red-500">
            Error loading children: {
              (childrenError as ApiError).message || 'An unknown error occurred'
            }
          </p>
        )}
        {children.length > 0 ? (
          <div className="space-y-8">
            {children.map((child) => (
              <div key={child.id} className="bg-white rounded-lg shadow overflow-hidden">
                <div className="px-6 py-4 border-b border-gray-200">
                  <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold text-gray-900">
                      {child.name} <span className="text-gray-500 text-sm">({child.age} years old)</span>
                    </h2>
                    <div className="flex items-center">
                      <button
                        onClick={() => {
                          // Ensure all required properties are present and correctly typed
                          if (child.id && child.name && typeof child.age === 'number') {
                            setSelectedChild({
                              id: child.id,
                              name: child.name,
                              age: child.age
                            });
                            setIsEditModalOpen(true);
                          }
                        }}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        <PencilSquareIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => {
                          // Ensure child.id is defined before using it
                          if (child.id) {
                            setItemToDelete({ type: 'child', id: child.id });
                            setIsDeleteConfirmationOpen(true);
                          }
                        }}
                        className="ml-2 inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded text-red-600 bg-red-200 hover:bg-red-300"
                      >
                        Delete Child
                      </button>
                    </div>
                  </div>
                </div>
                <div className="px-6 py-4">
                  {child.camp_schedules && child.camp_schedules.length > 0 ? (
                    <ul className="divide-y divide-gray-200">
                      {child.camp_schedules.map((schedule) => (
                        <li key={schedule.id} className="py-3">
                          <div className="flex justify-between items-center">
                            <div>
                              <p className="text-sm font-medium text-gray-900">
                                {schedule.camp?.name || 'Unknown Camp'}
                              </p>
                              <p className="text-sm text-gray-500">
                                {new Date(schedule.start_date).toLocaleDateString()} - {new Date(schedule.end_date).toLocaleDateString()}
                              </p>
                              <div className="mt-2">
                                <CalendarExport
                                  childName={child.name}
                                  campName={schedule.camp?.name || 'Summer Camp'}
                                  startDate={schedule.start_date}
                                  endDate={schedule.end_date}
                                  address={schedule.camp?.address}
                                  city={schedule.camp?.city}
                                  state={schedule.camp?.state}
                                  zipCode={schedule.camp?.zip_code}
                                />
                              </div>
                            </div>
                            <button
                              onClick={() => setDeleteConfirmation({
                                scheduleId: schedule.id,
                                childName: child.name,
                                campName: schedule.camp?.name || 'Summer Camp'
                              })}
                              className="text-sm text-red-600 hover:text-red-900"
                            >
                              <XMarkIcon className="h-5 w-5" />
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-sm text-gray-500">
                      {isFetching ? 'Loading schedules...' : 'No camps scheduled'}
                    </p>
                  )}
                </div>

                <div className="px-6 py-4">
                  <button
                    onClick={() => {
                      setIsAddScheduleModalOpen(true);
                      setSelectedChildId(child.id);
                    }}
                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded text-blue-600 bg-blue-50 hover:bg-blue-100"
                  >
                    <PlusIcon className="h-4 w-4 mr-1" />
                    Add Camp
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <h2 className="text-xl font-medium text-gray-900 mb-4">No children added yet</h2>
            <p className="text-gray-500 mb-4">Add your first child to start managing camp schedules</p>
            <button
              onClick={() => setIsAddChildModalOpen(true)}
              className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                ${children.length >= 5 
                  ? 'bg-gray-400 cursor-not-allowed' 
                  : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                }`}
              disabled={children.length >= 5}
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Add Child
            </button>
          </div>
        )}

        <AddChildModal
          isOpen={isAddChildModalOpen}
          onClose={() => setIsAddChildModalOpen(false)}
          onAdd={handleAddChild}
        />

        <AddToCalendarModal
          isOpen={isAddScheduleModalOpen}
          onClose={() => setIsAddScheduleModalOpen(false)}
          onAdd={handleAddSchedule}
          childId={selectedChildId}
          childName={children.find(child => child.id === selectedChildId)?.name || ''}
        />

        {/* Delete Confirmation Dialog */}
        <Transition.Root show={!!deleteConfirmation} as={Fragment}>
          <Dialog as="div" className="relative z-[9999]" onClose={() => setDeleteConfirmation(null)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500/75 backdrop-blur-sm transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            Remove Camp Schedule
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to remove {deleteConfirmation?.campName} from {deleteConfirmation?.childName}'s schedule? This action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={handleRemoveSchedule}
                      >
                        Remove
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setDeleteConfirmation(null)}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Edit Modal */}
        <Transition.Root show={isEditModalOpen} as={Fragment}>
          <Dialog as="div" className="relative z-[9999]" onClose={() => setIsEditModalOpen(false)}>
            <div className="fixed inset-0 bg-gray-500/75 backdrop-blur-sm transition-opacity" />
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Edit Child
                        </Dialog.Title>
                        <div className="mt-2">
                          <div className="space-y-4">
                            <div>
                              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={selectedChild?.name || ''}
                                onChange={(e) => {
                                  if (selectedChild) {
                                    setSelectedChild({
                                      ...selectedChild,
                                      name: e.target.value
                                    });
                                  }
                                }}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                            <div>
                              <label htmlFor="age" className="block text-sm font-medium text-gray-700">
                                Age
                              </label>
                              <input
                                type="number"
                                name="age"
                                id="age"
                                value={selectedChild?.age || ''}
                                onChange={(e) => {
                                  if (selectedChild) {
                                    setSelectedChild({
                                      ...selectedChild,
                                      age: Number(e.target.value)
                                    });
                                  }
                                }}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                        onClick={handleEditChild}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setIsEditModalOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={isDeleteConfirmationOpen} as={Fragment}>
          <Dialog as="div" className="relative z-[9999]" onClose={() => setIsDeleteConfirmationOpen(false)}>
            <div className="fixed inset-0 bg-gray-500/75 backdrop-blur-sm transition-opacity" />
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <h3 className="text-lg font-semibold">Are you sure?</h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this child? This action cannot be undone.
                        </p>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={() => {
                          if (itemToDelete) {
                            handleDeleteChild(itemToDelete.id);
                          }
                          setIsDeleteConfirmationOpen(false);
                        }}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setIsDeleteConfirmationOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
import { supabase } from './supabase';
import type { Camp, CampFavorite } from '../types/database';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

async function retryOperation<T>(operation: () => Promise<T>, retries = MAX_RETRIES): Promise<T> {
  let lastError;
  
  for (let i = 0; i < retries; i++) {
    try {
      return await operation();
    } catch (error) {
      console.error(`Attempt ${i + 1} failed:`, error);
      lastError = error;
      
      if (i < retries - 1) {
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * Math.pow(2, i)));
      }
    }
  }
  
  throw lastError;
}

export const api = {
  camps: {
    async search({ query, location, category, ageRange, zipCode }: {
      query?: string;
      location?: { lat: number; lng: number; radius: number };
      category?: string;
      ageRange?: { min: number; max: number };
      zipCode?: string;
    } = {}) {
      return retryOperation(async () => {
        let queryBuilder = supabase
          .from('camps')
          .select(`
            *,
            camp_favorites!left(*),
            camp_images(*)
          `)
          .order('created_at', { ascending: false });

        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          queryBuilder = queryBuilder.eq('camp_favorites.user_id', user.id);
        }

        if (query) {
          queryBuilder = queryBuilder.or(`name.ilike.%${query}%,description.ilike.%${query}%`);
        }

        if (zipCode) {
          queryBuilder = queryBuilder.eq('zip_code', zipCode);
        } else if (location) {
          const lat = location.lat;
          const lng = location.lng;
          const radius = location.radius;
          
          queryBuilder = queryBuilder
            .gte('latitude', lat - radius/111.0)
            .lte('latitude', lat + radius/111.0)
            .gte('longitude', lng - radius/(111.0 * Math.cos(lat * Math.PI/180)))
            .lte('longitude', lng + radius/(111.0 * Math.cos(lat * Math.PI/180)));
        }

        if (category) {
          queryBuilder = queryBuilder.eq('category_id', category);
        }

        if (ageRange) {
          queryBuilder = queryBuilder
            .gte('min_age', ageRange.min)
            .lte('max_age', ageRange.max);
        }

        const { data, error } = await queryBuilder;
        
        if (error) {
          console.error('Supabase query error:', error);
          throw error;
        }
        
        return data as (Camp & { camp_favorites: CampFavorite[] })[] || [];
      });
    },

    async getFavorites() {
      return retryOperation(async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('Not authenticated');

        const { data, error } = await supabase
          .from('camps')
          .select(`
            *,
            camp_favorites!inner(*),
            camp_images(*)
          `)
          .eq('camp_favorites.user_id', user.id)
          .order('created_at', { ascending: false });

        if (error) {
          console.error('Error fetching favorites:', error);
          throw error;
        }

        return data as Camp[] || [];
      });
    },

    async toggleFavorite(campId: string) {
      return retryOperation(async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('Not authenticated');

        // Check if already favorited
        const { data: favorites, error: fetchError } = await supabase
          .from('camp_favorites')
          .select('id')
          .eq('camp_id', campId)
          .eq('user_id', user.id);

        if (fetchError) throw fetchError;

        if (favorites && favorites.length > 0) {
          // Remove favorite
          const { error } = await supabase
            .from('camp_favorites')
            .delete()
            .eq('id', favorites[0].id);

          if (error) throw error;
          return false;
        } else {
          // Add favorite
          const { error } = await supabase
            .from('camp_favorites')
            .insert({ camp_id: campId, user_id: user.id });

          if (error) throw error;
          return true;
        }
      });
    }
  }
};
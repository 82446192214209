import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSession } from '@supabase/auth-helpers-react';
import { PlusIcon, PencilSquareIcon, TrashIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { supabase } from '../lib/supabase';
import AdminCampOverlay from '../components/AdminCampOverlay';
import type { Camp } from '../types/database';
import toast from 'react-hot-toast';

type Tab = 'camps' | 'users';
type SortField = 'name' | 'created_at' | 'updated_at';
type SortDirection = 'asc' | 'desc';

export default function AdminPage(): JSX.Element {
  const [selectedCamp, setSelectedCamp] = useState<Camp | null>(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>('camps');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState<SortField>('updated_at');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  
  const session = useSession();
  const queryClient = useQueryClient();

  // Check admin status using RPC function
  const { data: isAdmin, isLoading: isCheckingAdmin } = useQuery(
    ['is-admin', session?.user?.id],
    async () => {
      if (!session?.user?.id) return false;
      
      const { data, error } = await supabase
        .rpc('check_is_admin', {
          user_id: session.user.id
        });
      
      if (error) {
        console.error('Error checking admin status:', error);
        return false;
      }
      
      return !!data;
    },
    {
      enabled: !!session?.user?.id,
      staleTime: 30000, // Cache for 30 seconds
      cacheTime: 60000, // Keep in cache for 1 minute
      retry: false
    }
  );

  // Fetch all camps
  const { data: camps = [] } = useQuery(
    ['admin-camps', sortField, sortDirection, searchQuery],
    async () => {
      let query = supabase
        .from('camps')
        .select(`
          *,
          camp_images (
            id,
            url,
            type,
            alt_text
          )
        `)
        .order(sortField, { ascending: sortDirection === 'asc' });

      if (searchQuery) {
        query = query.or(`name.ilike.%${searchQuery}%,description.ilike.%${searchQuery}%`);
      }

      const { data, error } = await query;
      if (error) throw error;
      return data as Camp[];
    },
    {
      enabled: !!isAdmin && activeTab === 'camps'
    }
  );

  // Fetch all users
  const { data: users = [] } = useQuery(
    ['admin-users'],
    async () => {
      // Get profiles
      const { data: profiles, error: profilesError } = await supabase
        .from('profiles')
        .select('*')  // Just get all profile fields
        .order('created_at', { ascending: false });

      if (profilesError) {
        console.error('Error fetching profiles:', profilesError);
        throw profilesError;
      }

      console.log('Fetched profiles:', profiles); // Debug log

      // Get admin status
      const { data: adminUsers, error: adminError } = await supabase
        .from('admin_users')
        .select('id');

      if (adminError) throw adminError;
      const adminIds = new Set(adminUsers.map(admin => admin.id));

      return profiles.map(profile => ({
        ...profile,
        isAdmin: adminIds.has(profile.id)
      }));
    },
    {
      enabled: !!isAdmin && activeTab === 'users'
    }
  );

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleDeleteCamp = async (camp: Camp) => {
    try {
      // First delete all favorites associated with this camp
      const { error: favoritesError } = await supabase
        .from('camp_favorites')
        .delete()
        .eq('camp_id', camp.id);

      if (favoritesError) throw favoritesError;

      // Then delete all images associated with this camp
      const { error: imagesError } = await supabase
        .from('camp_images')
        .delete()
        .eq('camp_id', camp.id);

      if (imagesError) throw imagesError;

      // Finally delete the camp itself
      const { error: campError } = await supabase
        .from('camps')
        .delete()
        .eq('id', camp.id);

      if (campError) throw campError;

      void queryClient.invalidateQueries(['admin-camps']);
      toast.success('Camp deleted successfully');
    } catch (error) {
      console.error('Error deleting camp:', error);
      toast.error('Failed to delete camp');
    }
  };

  const handleToggleAdmin = async (userId: string, isCurrentlyAdmin: boolean) => {
    try {
      if (isCurrentlyAdmin) {
        const { error } = await supabase
          .from('admin_users')
          .delete()
          .eq('id', userId);

        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('admin_users')
          .insert({ id: userId });

        if (error) throw error;
      }

      void queryClient.invalidateQueries(['admin-users']);
      toast.success(`Admin status ${isCurrentlyAdmin ? 'removed' : 'granted'}`);
    } catch (error) {
      console.error('Error toggling admin status:', error);
      toast.error('Failed to update admin status');
    }
  };

  const handleEditCamp = (camp: Camp) => {
    console.log('Opening edit modal with camp:', camp);
    setSelectedCamp(camp);
    setIsOverlayOpen(true);
  };

  if (isCheckingAdmin) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Access Denied</h1>
          <p className="text-gray-500">You don't have permission to access this page.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Admin Dashboard</h1>
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-200 mb-8">
          <nav className="-mb-px flex space-x-8">
            <button
              onClick={() => setActiveTab('camps')}
              className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === 'camps'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
              `}
            >
              Manage Camps
            </button>
            <button
              onClick={() => setActiveTab('users')}
              className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === 'users'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
              `}
            >
              Manage Users
            </button>
          </nav>
        </div>

        {activeTab === 'camps' && (
          <div>
            <div className="mb-6 flex justify-between items-center">
              <div className="relative w-64">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search camps..."
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                />
                <MagnifyingGlassIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
              </div>
              <button
                onClick={() => {
                  setSelectedCamp(null);
                  setIsOverlayOpen(true);
                }}
                className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                Add Camp
              </button>
            </div>

            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() => handleSort('name')}
                      >
                        Name
                        {sortField === 'name' && (
                          <span className="ml-1">
                            {sortDirection === 'asc' ? '↑' : '↓'}
                          </span>
                        )}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() => handleSort('created_at')}
                      >
                        Created
                        {sortField === 'created_at' && (
                          <span className="ml-1">
                            {sortDirection === 'asc' ? '↑' : '↓'}
                          </span>
                        )}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() => handleSort('updated_at')}
                      >
                        Last Updated
                        {sortField === 'updated_at' && (
                          <span className="ml-1">
                            {sortDirection === 'asc' ? '↑' : '↓'}
                          </span>
                        )}
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {camps.map((camp) => (
                      <tr key={camp.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{camp.name}</div>
                          <div className="text-sm text-gray-500">{camp.city}, {camp.state}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(camp.created_at).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(camp.updated_at).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                          <button
                            onClick={() => handleEditCamp(camp)}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            <PencilSquareIcon className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteCamp(camp)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'users' && (
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      User
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Joined
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((user) => {
                    console.log('Rendering user:', user);
                    return (
                      <tr key={user.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {user.full_name || 'No name'}
                          </div>
                          <div className="text-sm text-gray-500">{user.email}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(user.created_at).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            user.isAdmin
                              ? 'bg-green-100 text-green-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}>
                            {user.isAdmin ? 'Admin' : 'User'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => handleToggleAdmin(user.id, user.isAdmin)}
                            className={`${
                              user.isAdmin
                                ? 'text-red-600 hover:text-red-900'
                                : 'text-blue-600 hover:text-blue-900'
                            }`}
                          >
                            {user.isAdmin ? 'Remove Admin' : 'Make Admin'}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <AdminCampOverlay
          camp={selectedCamp}
          isOpen={isOverlayOpen}
          onClose={() => {
            setIsOverlayOpen(false);
            setSelectedCamp(null);
          }}
          onSave={() => {
            void queryClient.invalidateQueries(['admin-camps']);
          }}
        />
      </div>
    </div>
  );
}
import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, PhotoIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';
import type { Camp } from '../types/database';
import toast from 'react-hot-toast';

interface AdminCampOverlayProps {
  camp: Camp | null;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

interface CampFormData {
  name: string;
  description: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  latitude: number;
  longitude: number;
  website_url: string;
  phone: string;
  email: string;
  min_age: number;
  max_age: number;
  category_id: string;
}

export default function AdminCampOverlay({ camp, isOpen, onClose, onSave }: AdminCampOverlayProps) {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedLogo, setSelectedLogo] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string>('');
  const [imagesPreviews, setImagesPreviews] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [currentCamp, setCurrentCamp] = useState<Camp | null>(camp);

  const { register, handleSubmit, reset, formState: { errors } } = useForm<CampFormData>();

  // Fetch categories for the dropdown
  const { data: categories = [] } = useQuery(['camp-categories'], async () => {
    const { data, error } = await supabase
      .from('camp_categories')
      .select('*');
    if (error) throw error;
    return data;
  });

  useEffect(() => {
    if (camp) {
      reset({
        name: camp.name,
        description: camp.description || '',
        address: camp.address,
        city: camp.city,
        state: camp.state,
        zip_code: camp.zip_code,
        latitude: camp.latitude,
        longitude: camp.longitude,
        website_url: camp.website_url || '',
        phone: camp.phone || '',
        email: camp.email || '',
        min_age: camp.min_age || 0,
        max_age: camp.max_age || 0,
        category_id: camp.category_id || ''
      });
    } else {
      reset({
        name: '',
        description: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        latitude: 0,
        longitude: 0,
        website_url: '',
        phone: '',
        email: '',
        min_age: 0,
        max_age: 0,
        category_id: ''
      });
    }
  }, [camp, reset]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      setSelectedImages(fileArray);
      
      // Create previews
      const newPreviews = fileArray.map(file => URL.createObjectURL(file));
      setImagesPreviews(prev => {
        // Clean up old previews
        prev.forEach(url => URL.revokeObjectURL(url));
        return newPreviews;
      });
    }
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      setSelectedLogo(files[0]);
      
      // Create preview
      setLogoPreview(prev => {
        if (prev) URL.revokeObjectURL(prev);
        return URL.createObjectURL(files[0]);
      });
    }
  };

  // Cleanup previews on unmount
  useEffect(() => {
    return () => {
      if (logoPreview) URL.revokeObjectURL(logoPreview);
      imagesPreviews.forEach(url => URL.revokeObjectURL(url));
    };
  }, [logoPreview, imagesPreviews]);

  const sanitizeFilename = (filename: string): string => {
    // Remove spaces and special characters, keep extension
    const ext = filename.substring(filename.lastIndexOf('.'));
    const name = filename.substring(0, filename.lastIndexOf('.'))
      .replace(/[^a-z0-9]/gi, '-')
      .toLowerCase();
    return `${name}${ext}`;
  };

  const onSubmit = async (data: CampFormData) => {
    try {
      setUploading(true);

      if (camp) {
        // Update existing camp
        const { error: updateError } = await supabase
          .from('camps')
          .update({
            ...data,
            updated_at: new Date().toISOString()
          })
          .eq('id', camp.id);

        if (updateError) throw updateError;

        // Handle image uploads for existing camp
        if (selectedLogo) {
          const sanitizedFilename = sanitizeFilename(selectedLogo.name);
          const logoPath = `${camp.id}/logo-${Date.now()}-${sanitizedFilename}`;
          const { error: uploadError } = await supabase.storage
            .from('camp-images')
            .upload(logoPath, selectedLogo, {
              cacheControl: '3600',
              upsert: true
            });

          if (uploadError) throw uploadError;

          const { data: { publicUrl } } = supabase.storage
            .from('camp-images')
            .getPublicUrl(logoPath);

          const { error: imageError } = await supabase
            .from('camp_images')
            .insert({
              camp_id: camp.id,
              url: publicUrl,
              type: 'logo',
              alt_text: `${data.name} logo`
            });

          if (imageError) throw imageError;
        }

        for (const image of selectedImages) {
          const sanitizedFilename = sanitizeFilename(image.name);
          const imagePath = `${camp.id}/photo-${Date.now()}-${sanitizedFilename}`;
          const { error: uploadError } = await supabase.storage
            .from('camp-images')
            .upload(imagePath, image, {
              cacheControl: '3600',
              upsert: true
            });

          if (uploadError) throw uploadError;

          const { data: { publicUrl } } = supabase.storage
            .from('camp-images')
            .getPublicUrl(imagePath);

          const { error: imageError } = await supabase
            .from('camp_images')
            .insert({
              camp_id: camp.id,
              url: publicUrl,
              type: 'photo',
              alt_text: `${data.name} activity photo`
            });

          if (imageError) throw imageError;
        }

        toast.success('Camp updated successfully');
      } else {
        // Create new camp
        const { data: newCamp, error: insertError } = await supabase
          .from('camps')
          .insert(data)
          .select()
          .single();

        if (insertError) throw insertError;

        // Handle image uploads for new camp
        if (selectedLogo && newCamp) {
          const sanitizedFilename = sanitizeFilename(selectedLogo.name);
          const logoPath = `${newCamp.id}/logo-${Date.now()}-${sanitizedFilename}`;
          const { error: uploadError } = await supabase.storage
            .from('camp-images')
            .upload(logoPath, selectedLogo, {
              cacheControl: '3600',
              upsert: true
            });

          if (uploadError) throw uploadError;

          const { data: { publicUrl } } = supabase.storage
            .from('camp-images')
            .getPublicUrl(logoPath);

          const { error: imageError } = await supabase
            .from('camp_images')
            .insert({
              camp_id: newCamp.id,
              url: publicUrl,
              type: 'logo',
              alt_text: `${data.name} logo`
            });

          if (imageError) throw imageError;
        }

        if (newCamp) {
          for (const image of selectedImages) {
            const sanitizedFilename = sanitizeFilename(image.name);
            const imagePath = `${newCamp.id}/photo-${Date.now()}-${sanitizedFilename}`;
            const { error: uploadError } = await supabase.storage
              .from('camp-images')
              .upload(imagePath, image, {
                cacheControl: '3600',
                upsert: true
              });

            if (uploadError) throw uploadError;

            const { data: { publicUrl } } = supabase.storage
              .from('camp-images')
              .getPublicUrl(imagePath);

            const { error: imageError } = await supabase
              .from('camp_images')
              .insert({
                camp_id: newCamp.id,
                url: publicUrl,
                type: 'photo',
                alt_text: `${data.name} activity photo`
            });

            if (imageError) throw imageError;
          }
        }

        toast.success('Camp created successfully');
      }

      onSave();
      onClose();
    } catch (error: any) {
      console.error('Error:', error);
      toast.error(error.message || 'Failed to save camp');
    } finally {
      setUploading(false);
    }
  };

  const logoImage = camp?.camp_images?.find(img => img.type === 'logo');
  const otherImages = camp?.camp_images?.filter(img => img.type !== 'logo') || [];

  const handleRemoveImage = async (imageId: string) => {
    try {
      const { error } = await supabase
        .from('camp_images')
        .delete()
        .eq('id', imageId);

      if (error) throw error;
      
      // Update local state to remove the image
      setCurrentCamp((prev: Camp | null) => {
        if (!prev) return null;
        return {
          ...prev,
          camp_images: prev.camp_images?.filter((img: { id: string }) => img.id !== imageId)
        };
      });

      toast.success('Image removed successfully');
    } catch (error) {
      console.error('Error removing image:', error);
      toast.error('Failed to remove image');
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="absolute right-0 top-0 pr-4 pt-4">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    <div className="space-y-6">
                      <div>
                        <h3 className="text-lg font-semibold leading-6 text-gray-900">
                          {camp ? 'Edit Camp' : 'Add New Camp'}
                        </h3>
                      </div>

                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                        <div className="sm:col-span-2">
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <input
                            type="text"
                            {...register('name', { required: true })}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                          {errors.name && <span className="text-red-500 text-sm">Required</span>}
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                            Description
                          </label>
                          <textarea
                            {...register('description')}
                            rows={3}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                            Address
                          </label>
                          <input
                            type="text"
                            {...register('address', { required: true })}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                            City
                          </label>
                          <input
                            type="text"
                            {...register('city', { required: true })}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                            State
                          </label>
                          <input
                            type="text"
                            {...register('state', { required: true })}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="zip_code" className="block text-sm font-medium text-gray-700">
                            ZIP Code
                          </label>
                          <input
                            type="text"
                            {...register('zip_code', { required: true })}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                            Category
                          </label>
                          <select
                            {...register('category_id', { required: true })}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          >
                            <option value="">Select a category</option>
                            {categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div>
                          <label htmlFor="latitude" className="block text-sm font-medium text-gray-700">
                            Latitude
                          </label>
                          <input
                            type="number"
                            step="any"
                            {...register('latitude', { required: true })}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="longitude" className="block text-sm font-medium text-gray-700">
                            Longitude
                          </label>
                          <input
                            type="number"
                            step="any"
                            {...register('longitude', { required: true })}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="min_age" className="block text-sm font-medium text-gray-700">
                            Min Age
                          </label>
                          <input
                            type="number"
                            {...register('min_age', { required: true, min: 0 })}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="max_age" className="block text-sm font-medium text-gray-700">
                            Max Age
                          </label>
                          <input
                            type="number"
                            {...register('max_age', { required: true, min: 0 })}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="website_url" className="block text-sm font-medium text-gray-700">
                            Website URL
                          </label>
                          <input
                            type="url"
                            {...register('website_url')}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                            placeholder="https://example.com"
                          />
                        </div>

                        <div>
                          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            Phone
                          </label>
                          <input
                            type="tel"
                            {...register('phone')}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                          </label>
                          <input
                            type="email"
                            {...register('email')}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        {/* Image Upload Section */}
                        <div className="sm:col-span-2 space-y-6">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Logo</label>
                            <div className="mt-1 flex items-center space-x-4">
                              <div className="flex-shrink-0 h-16 w-16 rounded-lg border border-gray-200 overflow-hidden">
                                {logoPreview ? (
                                  <img
                                    src={logoPreview}
                                    alt="Logo preview"
                                    className="h-full w-full object-cover"
                                  />
                                ) : (
                                  <div className="h-full w-full flex items-center justify-center bg-gray-50">
                                    <PhotoIcon className="h-8 w-8 text-gray-300" />
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleLogoChange}
                                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                              />
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">Additional Images</label>
                            <div className="mt-1 space-y-4">
                              <input
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={handleImageChange}
                                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                              />
                              {imagesPreviews.length > 0 && (
                                <div className="grid grid-cols-3 gap-4">
                                  {imagesPreviews.map((preview, index) => (
                                    <div key={index} className="relative h-24 rounded-lg border border-gray-200 overflow-hidden">
                                      <img
                                        src={preview}
                                        alt={`Preview ${index + 1}`}
                                        className="h-full w-full object-cover"
                                      />
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Logo Section */}
                        <div className="mt-4">
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            Logo
                          </label>
                          <div className="border border-gray-200 rounded-lg p-4">
                            {logoImage ? (
                              <div className="relative group w-32 h-32 mx-auto">
                                <img
                                  src={logoImage.url}
                                  alt={logoImage.alt_text || 'Camp logo'}
                                  className="h-full w-full object-cover rounded-lg border border-gray-200"
                                />
                                <button
                                  type="button"
                                  onClick={() => handleRemoveImage(logoImage.id)}
                                  className="absolute -top-2 -right-2 bg-white rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity z-10"
                                >
                                  <XCircleIcon className="h-6 w-6 text-red-500 hover:text-red-600" />
                                  <span className="sr-only">Remove logo</span>
                                </button>
                              </div>
                            ) : (
                              <div className="text-center py-4 text-gray-500 text-sm">
                                No logo uploaded
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Other Images Section */}
                        <div className="mt-4">
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            Photos ({otherImages.length})
                          </label>
                          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 border border-gray-200 rounded-lg p-4">
                            {otherImages.length > 0 ? (
                              otherImages.map((image) => (
                                <div 
                                  key={image.id} 
                                  className="relative group aspect-square bg-gray-100"
                                >
                                  <img
                                    src={image.url}
                                    alt={image.alt_text || `Camp photo`}
                                    className="h-full w-full object-cover rounded-lg border border-gray-200"
                                  />
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveImage(image.id)}
                                    className="absolute -top-2 -right-2 bg-white rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity z-10"
                                  >
                                    <XCircleIcon className="h-6 w-6 text-red-500 hover:text-red-600" />
                                    <span className="sr-only">Remove image</span>
                                  </button>
                                </div>
                              ))
                            ) : (
                              <div className="col-span-full text-center py-4 text-gray-500 text-sm">
                                No photos uploaded yet
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="submit"
                      disabled={uploading}
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {uploading ? 'Saving...' : 'Save'}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
import { useEffect, useState, ReactElement } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import { LatLngExpression, Icon, Marker as LeafletMarker } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import type { Camp } from '../types/database';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Fix Leaflet's default icon path issues
const defaultIcon = new Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

LeafletMarker.prototype.options.icon = defaultIcon;

interface CampMapProps {
  camps: Camp[];
  center: [number, number];
  zoom: number;
  onCampSelect: (camp: Camp) => void;
  onMapMove?: (center: [number, number]) => void;
}

// Map event handler component
function MapEvents({ center, zoom, onMapMove }: { 
  center: [number, number];
  zoom: number;
  onMapMove?: (center: [number, number]) => void;
}): ReactElement | null {
  const map = useMap();
  const [showHelper, setShowHelper] = useState(false);
  const [lastZoom, setLastZoom] = useState(zoom);

  // Update view when center or zoom changes
  useEffect(() => {
    const currentCenter = map.getCenter();
    const centerChanged = currentCenter.lat !== center[0] || currentCenter.lng !== center[1];
    const zoomChanged = zoom !== lastZoom;

    if (zoomChanged) {
      // If zoom has changed, update both center and zoom
      map.setView(center, zoom, { animate: false });
      setLastZoom(zoom);
    } else if (centerChanged) {
      // If only center changed, preserve current zoom
      const currentZoom = map.getZoom();
      map.setView(center, currentZoom, { animate: true });
    }
  }, [map, center, zoom, lastZoom]);

  // Handle map movements
  useMapEvents({
    moveend: () => {
      if (onMapMove) {
        const newCenter = map.getCenter();
        const currentCenter: [number, number] = [newCenter.lat, newCenter.lng];
        if (currentCenter[0] !== center[0] || currentCenter[1] !== center[1]) {
          onMapMove(currentCenter);
        }
      }
    }
  });

  // Add wheel event listener directly to map container
  useEffect(() => {
    const container = map.getContainer();
    
    const handleWheel = (e: WheelEvent) => {
      if (!e.ctrlKey) {
        e.preventDefault();
        if (!showHelper) {
          setShowHelper(true);
          setTimeout(() => setShowHelper(false), 2000);
        }
      } else {
        e.preventDefault();
        const delta = -e.deltaY;
        const zoom = map.getZoom();
        const newZoom = Math.min(Math.max(delta > 0 ? zoom + 0.25 : zoom - 0.25, 3), 18);
        map.setZoom(newZoom, { animate: false });
      }
    };

    container.addEventListener('wheel', handleWheel, { passive: false });
    
    return () => {
      container.removeEventListener('wheel', handleWheel);
    };
  }, [map, showHelper]);

  return showHelper ? (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black/75 text-white px-4 py-2 rounded-lg shadow-lg text-sm pointer-events-none z-[1000] transition-opacity duration-300">
      Use Ctrl + scroll to zoom the map
    </div>
  ) : null;
}

export default function CampMap({ camps, center, zoom, onCampSelect, onMapMove }: CampMapProps) {
  return (
    <div className="h-full rounded-lg overflow-hidden shadow-md relative">
      <MapContainer
        center={center as LatLngExpression}
        zoom={zoom}
        className="h-full w-full"
        scrollWheelZoom={false}
        zoomControl={true}
        keyboard={true}
        minZoom={3}
        maxZoom={18}
        zoomSnap={0}
        zoomAnimation={false}
        doubleClickZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MapEvents center={center} zoom={zoom} onMapMove={onMapMove} />
        {camps.map((camp) => (
          <Marker
            key={camp.id}
            position={[camp.latitude, camp.longitude] as LatLngExpression}
          >
            <Popup>
              <div className="p-2">
                <h3 className="font-semibold">{camp.name}</h3>
                <p className="text-sm text-gray-600">{camp.address}</p>
                <button
                  onClick={() => onCampSelect(camp)}
                  className="mt-2 text-sm text-blue-500 hover:text-blue-600"
                >
                  View Details
                </button>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
} 
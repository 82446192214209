import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, CalendarDaysIcon } from '@heroicons/react/24/outline'
import { MapPinIcon, GlobeAltIcon, PhoneIcon, EnvelopeIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import type { Camp } from '../types/database'
import { getCampImage } from '../lib/images'
import { useSession } from '@supabase/auth-helpers-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { supabase } from '../lib/supabase'
import toast from 'react-hot-toast'

interface CampDetailsOverlayProps {
  camp: Camp | null;
  isOpen: boolean;
  onClose: () => void;
}

type ViewMode = 'details' | 'calendar';

export default function CampDetailsOverlay({ camp, isOpen, onClose }: CampDetailsOverlayProps) {
  const [heroImage, setHeroImage] = useState<string>('');
  const [logoImage, setLogoImage] = useState<string>('');
  const [viewMode, setViewMode] = useState<ViewMode>('details');
  const [selectedChild, setSelectedChild] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const session = useSession();
  const navigate = useNavigate();

  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setViewMode('details');
      setSelectedChild('');
      setStartDate('');
      setEndDate('');
      setIsSubmitting(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (camp) {
      // Get the first photo for hero image
      const photo = camp.camp_images?.find(img => img.type === 'photo');
      const logo = camp.camp_images?.find(img => img.type === 'logo');
      
      if (photo) {
        setHeroImage(photo.url);
      } else {
        const defaultImage = getCampImage(camp);
        setHeroImage(defaultImage);
      }

      if (logo) {
        setLogoImage(logo.url);
      }
    }
  }, [camp]);

  // Fetch children
  const { data: children = [] } = useQuery(
    ['children'],
    async () => {
      const { data, error } = await supabase
        .from('children')
        .select('*')
        .order('name');
      
      if (error) throw error;
      return data;
    },
    {
      enabled: viewMode === 'calendar' && !!session
    }
  );

  const handleAddToCalendar = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!session) {
      toast((t) => (
        <span className="flex items-center gap-2">
          <span className="whitespace-nowrap">Please sign in to add camps to your calendar</span>
          <button
            onClick={() => {
              toast.dismiss(t.id);
              navigate('/login');
            }}
            className="whitespace-nowrap bg-blue-500 text-white text-sm px-2 py-1 rounded hover:bg-blue-600 transition-colors"
          >
            Sign In
          </button>
        </span>
      ), {
        duration: 5000,
      });
      return;
    }
    setViewMode('calendar');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!camp) return;
    
    if (!selectedChild || !startDate || !endDate) {
      toast.error('Please fill in all fields');
      return;
    }

    if (new Date(endDate) < new Date(startDate)) {
      toast.error('End date must be after start date');
      return;
    }

    setIsSubmitting(true);

    try {
      const { error } = await supabase
        .from('camp_schedules')
        .insert({
          child_id: selectedChild,
          camp_id: camp.id,
          start_date: startDate,
          end_date: endDate
        });

      if (error) throw error;

      toast.success('Added to calendar successfully');
      navigate('/calendar');
    } catch (error) {
      console.error('Error adding to calendar:', error);
      toast.error('Failed to add to calendar');
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  const handleAddChild = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
    navigate('/calendar');
  };

  if (!camp) return null;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog 
        as="div" 
        className="relative z-[9999]" 
        onClose={onClose}
        onClick={(e) => e.stopPropagation()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel 
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="h-64 relative">
                  <img
                    src={heroImage}
                    alt={`${camp.name} activities`}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/35" />
                  {logoImage && (
                    <div className="absolute top-6 left-6">
                      <img
                        src={logoImage}
                        alt={`${camp.name} logo`}
                        className="w-16 h-16 rounded-lg shadow-lg object-cover bg-white"
                      />
                    </div>
                  )}
                  <div className="absolute right-0 top-0 pr-4 pt-4">
                    <button
                      type="button"
                      className="rounded-md bg-white/80 backdrop-blur-sm p-1 text-gray-400 hover:text-gray-500 transition-colors"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <Dialog.Title className="absolute bottom-4 left-6 text-3xl font-semibold text-white">
                    {camp.name}
                  </Dialog.Title>
                </div>

                {viewMode === 'details' ? (
                  <div className="bg-white px-6 py-6">
                    <div className="space-y-6">
                      <div>
                        <p className="text-gray-700 text-lg">{camp.description}</p>
                      </div>

                      <div className="border-t border-gray-200 pt-4">
                        <h4 className="text-lg font-medium text-gray-900 mb-3">Location & Contact</h4>
                        <div className="space-y-3">
                          <div className="flex items-center text-gray-600">
                            <MapPinIcon className="h-5 w-5 mr-2 flex-shrink-0" />
                            <span>{camp.address}, {camp.city}, {camp.state} {camp.zip_code}</span>
                          </div>
                          {camp.website_url && (
                            <div className="flex items-center text-gray-600">
                              <GlobeAltIcon className="h-5 w-5 mr-2 flex-shrink-0" />
                              <a 
                                href={camp.website_url} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="text-blue-600 hover:text-blue-800"
                                onClick={(e) => e.stopPropagation()}
                              >
                                Visit Website
                              </a>
                            </div>
                          )}
                          {camp.phone && (
                            <div className="flex items-center text-gray-600">
                              <PhoneIcon className="h-5 w-5 mr-2 flex-shrink-0" />
                              <a 
                                href={`tel:${camp.phone}`} 
                                className="text-blue-600 hover:text-blue-800"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {camp.phone}
                              </a>
                            </div>
                          )}
                          {camp.email && (
                            <div className="flex items-center text-gray-600">
                              <EnvelopeIcon className="h-5 w-5 mr-2 flex-shrink-0" />
                              <a 
                                href={`mailto:${camp.email}`} 
                                className="text-blue-600 hover:text-blue-800"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {camp.email}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="border-t border-gray-200 pt-4">
                        <h4 className="text-lg font-medium text-gray-900 mb-3">Age Range</h4>
                        <div className="flex items-center text-gray-600">
                          <UserGroupIcon className="h-5 w-5 mr-2 flex-shrink-0" />
                          <span>Ages {camp.min_age} to {camp.max_age}</span>
                        </div>
                      </div>

                      <div className="border-t border-gray-200 pt-4">
                        <button
                          type="button"
                          onClick={handleAddToCalendar}
                          className="w-full inline-flex justify-center items-center rounded-md bg-blue-600 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 transition-colors"
                        >
                          <CalendarDaysIcon className="h-5 w-5 mr-2" />
                          Add to Calendar
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="bg-white px-6 py-6">
                    <form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
                      <div className="space-y-6">
                        <div>
                          <button
                            type="button"
                            onClick={() => setViewMode('details')}
                            className="text-sm text-blue-600 hover:text-blue-800"
                          >
                            ← Back to Details
                          </button>
                        </div>

                        <div className="space-y-4">
                          {children.length === 0 ? (
                            <div className="text-center">
                              <p className="text-sm text-gray-500 mb-4">No children added yet</p>
                              <button
                                type="button"
                                onClick={handleAddChild}
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                              >
                                Add Child Profile
                              </button>
                            </div>
                          ) : (
                            <>
                              <div>
                                <label htmlFor="child" className="block text-sm font-medium text-gray-700">
                                  Select Child
                                </label>
                                <select
                                  id="child"
                                  value={selectedChild}
                                  onChange={(e) => setSelectedChild(e.target.value)}
                                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                  required
                                >
                                  <option value="">Select a child</option>
                                  {children.map((child) => (
                                    <option key={child.id} value={child.id}>
                                      {child.name} ({child.age} years old)
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div>
                                <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">
                                  Start Date
                                </label>
                                <input
                                  type="date"
                                  id="start-date"
                                  value={startDate}
                                  onChange={(e) => setStartDate(e.target.value)}
                                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                  required
                                />
                              </div>

                              <div>
                                <label htmlFor="end-date" className="block text-sm font-medium text-gray-700">
                                  End Date
                                </label>
                                <input
                                  type="date"
                                  id="end-date"
                                  value={endDate}
                                  onChange={(e) => setEndDate(e.target.value)}
                                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                  required
                                />
                              </div>

                              <div className="pt-4">
                                <button
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="w-full inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                  {isSubmitting ? 'Adding...' : 'Add to Calendar'}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
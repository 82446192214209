import { useState } from 'react';

interface EmailLinkProps {
  className?: string;
  showFullEmail?: boolean;
}

export function EmailLink({ className = "text-blue-600 hover:text-blue-800", showFullEmail = false }: EmailLinkProps) {
  const [email] = useState(() => {
    const user = 'info';
    const domain = 'campwhiz.com';
    return `${user}@${domain}`;
  });

  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        window.location.href = `mailto:${email}`;
      }}
      className={className}
    >
      {showFullEmail ? email : email.replace('@', ' [at] ')}
    </a>
  );
} 
import { CalendarDaysIcon } from '@heroicons/react/24/outline';

interface CalendarExportProps {
  childName: string;
  campName: string;
  startDate: string;
  endDate: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export default function CalendarExport({ 
  childName, 
  campName, 
  startDate, 
  endDate,
  address,
  city,
  state,
  zipCode
}: CalendarExportProps) {
  const generateICSFile = () => {
    // Add debug logging
    console.log('Calendar Export Props:', {
      address,
      city,
      state,
      zipCode
    });

    const formatAddress = () => {
      const parts = [];
      
      // Combine street address with city, state, zip
      if (address || city || state || zipCode) {
        const addressParts = [
          address,
          [city, state, zipCode].filter(Boolean).join(' ')
        ].filter(Boolean);
        
        const formattedAddress = addressParts.join(', ');
        console.log('Formatted Address:', formattedAddress);
        // Escape special characters and replace commas with \\,
        return formattedAddress.replace(/[,\n]/g, '\\$&');
      }
      
      return 'Location TBD';
    };

    const event = {
      start: new Date(startDate),
      end: new Date(endDate),
      title: `${campName} - ${childName}`.replace(/[,\n]/g, '\\$&'),
      description: `Summer camp schedule for ${childName} at ${campName}`.replace(/[,\n]/g, '\\$&'),
      location: formatAddress()
    };

    // Log the final event
    console.log('Calendar Event:', event);

    const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${event.start.toISOString().replace(/[-:]/g, '').split('.')[0]}Z
DTEND:${event.end.toISOString().replace(/[-:]/g, '').split('.')[0]}Z
SUMMARY:${event.title}
DESCRIPTION:${event.description}
LOCATION:${event.location}
END:VEVENT
END:VCALENDAR`;

    // Log the final ICS content
    console.log('ICS Content:', icsContent);

    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${campName}-${childName}.ics`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
      onClick={generateICSFile}
      className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
      Add to Calendar
    </button>
  );
}
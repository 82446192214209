import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { supabase } from '../lib/supabase';
import { calculateDistance } from '../lib/distance';
import CampCard from '../components/CampCard';
import CampMap from '../components/CampMap';
import CampDetailsOverlay from '../components/CampDetailsOverlay';
import { Skeleton } from '../components/ui/Skeleton';
import type { Camp } from '../types/database';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM, LOCATION_ZOOM, SEARCH_PLACEHOLDER } from '../lib/constants';
import 'leaflet/dist/leaflet.css';

interface LocationInfo {
  coords: [number, number];
  city: string;
  state: string;
}

export default function HomePage() {
  const [selectedCamp, setSelectedCamp] = useState<Camp | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [userLocation, setUserLocation] = useState<LocationInfo | null>(null);
  const [mapCenter, setMapCenter] = useState<[number, number]>([DEFAULT_MAP_CENTER.lat, DEFAULT_MAP_CENTER.lng]);
  const [mapZoom, setMapZoom] = useState(DEFAULT_MAP_ZOOM);
  const [radius, setRadius] = useState<number | null>(30);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [isRequestingLocation, setIsRequestingLocation] = useState(false);
  const [isMapLoading, setIsMapLoading] = useState(true);

  // Fetch camps
  const { data: camps = [], isLoading, refetch } = useQuery(['camps'], async () => {
    const { data, error } = await supabase
      .from('camps')
      .select('*, camp_images(*), camp_favorites(*), camp_categories!inner(*)');
    if (error) throw error;
    return data as Camp[];
  });

  // Fetch categories
  const { data: categories = [] } = useQuery(['camp-categories'], async () => {
    const { data, error } = await supabase
      .from('camp_categories')
      .select('*');
    if (error) throw error;
    return data;
  });

  // Get user location
  useEffect(() => {
    if (!('geolocation' in navigator)) {
      handleDefaultView('Geolocation is not supported by your browser.');
      return;
    }

    setIsRequestingLocation(true);

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
          );
          const data = await response.json();
          
          const updates = {
            coords: [latitude, longitude] as [number, number],
            city: data.city || 'your location',
            state: data.principalSubdivision || ''
          };
          
          setUserLocation(updates);
          setMapCenter(updates.coords);
          setRadius(30);
          setMapZoom(LOCATION_ZOOM);
          setLocationError(null);
        } catch (error) {
          console.error('Error getting city:', error);
          handleDefaultView('Unable to determine your city. Showing all camps across the US.');
        } finally {
          setIsRequestingLocation(false);
          setIsMapLoading(false);
        }
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          handleDefaultView('Showing all camps across the United States');
        } else {
          console.warn('Geolocation error:', error);
          handleDefaultView('Unable to get your location. Showing all camps across the US.');
        }
        setIsRequestingLocation(false);
        setIsMapLoading(false);
      },
      {
        timeout: 10000,
        enableHighAccuracy: false,
        maximumAge: 0
      }
    );
  }, []);

  // Helper to set default view state
  const handleDefaultView = (message: string) => {
    setUserLocation(null);
    setMapCenter([DEFAULT_MAP_CENTER.lat, DEFAULT_MAP_CENTER.lng]);
    setMapZoom(DEFAULT_MAP_ZOOM);
    setRadius(null);
    setLocationError(message);
    setIsRequestingLocation(false);
    setIsMapLoading(false);
  };

  // Function to get coordinates for a city/zip
  const getCityCoordinates = async (searchTerm: string) => {
    // First try to find the city in our database
    const [city, state] = searchTerm.split(',').map(s => s.trim());
    const matchingCamp = camps.find(camp => 
      camp.city.toLowerCase() === city.toLowerCase() &&
      camp.state.toLowerCase() === state.toLowerCase()
    );

    if (matchingCamp) {
      return { lat: matchingCamp.latitude, lng: matchingCamp.longitude };
    }

    // Fall back to Google Geocoding API if not found in database
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(searchTerm)}&components=country:US&key=${import.meta.env.VITE_GOOGLE_API_KEY}`
      );
      const data = await response.json();
      
      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      }
      return null;
    } catch (error) {
      console.error('Error getting city coordinates:', error);
      return null;
    }
  };

  // Get unique cities from camps
  const uniqueCities = Array.from(new Set(camps.map(camp => `${camp.city}, ${camp.state}`)))
    .sort();

  // Get search suggestions
  const getSearchSuggestions = () => {
    const query = searchQuery.trim().toLowerCase();
    
    // Only show suggestions after 3 characters
    if (query.length < 3) return { camps: [], cities: [] };
    
    // Find matching cities first (limit to 5)
    const matchingCities = uniqueCities
      .filter(city => city.toLowerCase().includes(query))
      .slice(0, 5);

    // Find matching camps (limit to 8)
    const matchingCamps = camps.filter(camp => 
      camp.name.toLowerCase().includes(query) ||
      camp.city.toLowerCase().includes(query)
    ).slice(0, 8);

    return { camps: matchingCamps, cities: matchingCities };
  };

  // Handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setShowDropdown(true);
  };

  // Handle city selection
  const handleCitySelect = async (city: string) => {
    setSearchQuery(city);
    setShowDropdown(false);
    setIsRequestingLocation(false);
    setIsMapLoading(true);

    try {
      const coords = await getCityCoordinates(city);
      if (coords) {
        const newCenter: [number, number] = [coords.lat, coords.lng];
        setMapCenter(newCenter);
        setMapZoom(LOCATION_ZOOM);
        setRadius(30);
        setUserLocation({
          coords: newCenter,
          city: city.split(',')[0].trim(),
          state: city.split(',')[1].trim()
        });
        setLocationError(null);
      } else {
        console.error('Could not get coordinates for city:', city);
        setLocationError('Could not find coordinates for this city.');
      }
    } catch (error) {
      console.error('Error updating map location:', error);
      setLocationError('Error finding this location.');
    } finally {
      setIsMapLoading(false);
    }
  };

  // Handle camp selection from dropdown
  const handleCampSelect = (camp: Camp) => {
    setSearchQuery(camp.name);
    setShowDropdown(false);
    setSelectedCamp(camp);
    setMapCenter([camp.latitude, camp.longitude]);
    setMapZoom(13);
  };

  // Handle radius change
  const handleRadiusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value ? Number(e.target.value) : null;
    setRadius(value);
    
    if (value) {
      // Adjust zoom level based on radius
      if (value === 10) setMapZoom(11);
      else if (value === 20) setMapZoom(10);
      else if (value === 30) setMapZoom(LOCATION_ZOOM);
      else if (value === 50) setMapZoom(7);
      else if (value === 100) setMapZoom(6);
    }
  };

  // Handle map movement
  const handleMapMove = (newCenter: [number, number]) => {
    setMapCenter(newCenter);
    // Only set radius if it's not already set and we have user location
    if (!radius && userLocation) {
      setRadius(30);
      // Don't set zoom here to preserve user's current zoom level
    }
  };

  // Filter camps based on search query, location, category, and map bounds
  const filteredCamps = camps.filter(camp => {
    const searchTerms = searchQuery.toLowerCase().trim().split(/[\s,]+/);
    
    // If a city is selected, don't filter by search terms
    const isCitySelected = userLocation && searchQuery.includes(userLocation.city);
    const matchesSearch = isCitySelected || !searchQuery || searchTerms.every(term => 
      camp.name.toLowerCase().includes(term)
    );

    // Check category match
    const matchesCategory = !selectedCategory || 
      camp.camp_categories?.name === selectedCategory;

    // Check distance - only if radius is selected and we're not in default view
    const isDefaultView = mapCenter && mapCenter[0] === DEFAULT_MAP_CENTER.lat && mapCenter[1] === DEFAULT_MAP_CENTER.lng;
    const distance = calculateDistance(
      mapCenter ? mapCenter[0] : DEFAULT_MAP_CENTER.lat,
      mapCenter ? mapCenter[1] : DEFAULT_MAP_CENTER.lng,
      camp.latitude,
      camp.longitude
    );
    const matchesDistance = isDefaultView || !radius || distance <= radius;

    return matchesSearch && matchesCategory && matchesDistance;
  });

  // Get search suggestions
  const { camps: suggestedCamps, cities: suggestedCities } = getSearchSuggestions();
  const hasSuggestions = suggestedCamps.length > 0 || suggestedCities.length > 0;

  return (
    <div className="min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Find the Perfect Summer Camp
          </h1>
          <p className="text-xl text-gray-500 mb-8">
            {isRequestingLocation ? (
              'Requesting your location...'
            ) : userLocation ? (
              `Camps near ${userLocation.city}, ${userLocation.state}`
            ) : locationError ? (
              locationError
            ) : (
              'Showing camps across the United States'
            )}
          </p>

          {/* Smart Search */}
          <div className="max-w-2xl mx-auto mb-8">
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                onFocus={() => setShowDropdown(true)}
                placeholder="Search camps or enter a city..."
                className="w-full px-4 py-3 pl-10 pr-4 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <MagnifyingGlassIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />

              {/* Smart Dropdown */}
              {showDropdown && searchQuery.length >= 3 && hasSuggestions && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-96 overflow-auto divide-y divide-gray-100">
                  {/* Cities Section */}
                  {suggestedCities.length > 0 && (
                    <div>
                      <div className="px-3 py-2 text-xs font-semibold text-gray-500 bg-gray-50 sticky top-0">
                        Cities
                      </div>
                      {suggestedCities.map((city) => (
                        <button
                          key={city}
                          onClick={() => handleCitySelect(city)}
                          className="w-full px-4 py-2 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 flex items-center gap-2"
                        >
                          <div className="flex-1">
                            <span className="font-medium">{city}</span>
                          </div>
                          <div className="text-sm text-gray-500">View area</div>
                        </button>
                      ))}
                    </div>
                  )}

                  {/* Camps Section */}
                  {suggestedCamps.length > 0 && (
                    <div>
                      <div className="px-3 py-2 text-xs font-semibold text-gray-500 bg-gray-50 sticky top-0">
                        Camps
                      </div>
                      {suggestedCamps.map((camp) => (
                        <button
                          key={camp.id}
                          onClick={() => handleCampSelect(camp)}
                          className="w-full px-4 py-2 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                        >
                          <div className="font-medium line-clamp-1">{camp.name}</div>
                          <div className="text-sm text-gray-500">{camp.city}, {camp.state}</div>
                        </button>
                      ))}
                    </div>
                  )}

                  {/* No Results */}
                  {!suggestedCities.length && !suggestedCamps.length && (
                    <div className="px-4 py-3 text-sm text-gray-500 text-center">
                      No matches found
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Category Chips */}
          <div className="flex flex-wrap justify-center gap-2 mb-8">
            <button
              onClick={() => setSelectedCategory('')}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                !selectedCategory
                  ? 'bg-blue-500 text-white'
                  : 'bg-white text-gray-600 hover:bg-blue-50'
              } border border-gray-200 shadow-sm hover:shadow`}
            >
              All Camps
            </button>
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.name)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                  selectedCategory === category.name
                    ? 'bg-blue-500 text-white'
                    : 'bg-white text-gray-600 hover:bg-blue-50'
                } border border-gray-200 shadow-sm hover:shadow`}
              >
                {category.name}
              </button>
            ))}
          </div>

          {/* Radius Dropdown */}
          <div className="w-full mt-4 max-w-xs mx-auto">
            <label htmlFor="radius" className="block text-sm font-medium text-gray-700 mb-1">
              Search Radius
            </label>
            <select
              id="radius"
              value={radius || ''}
              onChange={handleRadiusChange}
              className="w-full px-3 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              {!userLocation && mapCenter && mapCenter[0] === DEFAULT_MAP_CENTER.lat && mapCenter[1] === DEFAULT_MAP_CENTER.lng ? (
                <option value="">Select radius...</option>
              ) : (
                <option value={30}>30 miles</option>
              )}
              <option value={10}>10 miles</option>
              <option value={20}>20 miles</option>
              <option value={50}>50 miles</option>
              <option value={100}>100 miles</option>
            </select>
          </div>
        </div>

        {/* Map Section */}
        <div className="relative mb-16 h-[400px] rounded-lg overflow-hidden shadow-lg">
          {(isRequestingLocation || isMapLoading) && (
            <div className="absolute inset-0 bg-white bg-opacity-75 z-10 flex items-center justify-center">
              <div className="text-center">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-2"></div>
                <p className="text-gray-600">
                  {isRequestingLocation ? 'Getting your location...' : 'Updating map...'}
                </p>
              </div>
            </div>
          )}
          <CampMap
            camps={filteredCamps}
            center={mapCenter}
            zoom={mapZoom}
            onCampSelect={setSelectedCamp}
            onMapMove={handleMapMove}
          />
        </div>

        {/* Camp Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {isLoading ? (
            <div className="col-span-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[...Array(6)].map((_, i) => (
                <Skeleton key={i} className="h-96" />
              ))}
            </div>
          ) : filteredCamps.length > 0 ? (
            filteredCamps.map((camp) => (
              <CampCard
                key={camp.id}
                camp={camp}
                onViewDetails={(camp) => setSelectedCamp(camp)}
                onFavoriteChange={() => refetch()}
              />
            ))
          ) : (
            <div className="col-span-full text-center py-12">
              <p className="text-gray-500">No camps found matching your search criteria.</p>
            </div>
          )}
        </div>
      </div>

      {selectedCamp && (
        <CampDetailsOverlay
          camp={selectedCamp}
          isOpen={!!selectedCamp}
          onClose={() => setSelectedCamp(null)}
        />
      )}
    </div>
  );
}
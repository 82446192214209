// Cache duration in milliseconds (24 hours)
const CACHE_DURATION = 24 * 60 * 60 * 1000;

interface CacheEntry {
  url: string;
  timestamp: number;
}

// Use localStorage for persistent caching
const imageCache = new Map<string, CacheEntry>();

// Initialize cache from localStorage
try {
  const savedCache = localStorage.getItem('campImageCache');
  if (savedCache) {
    const parsed = JSON.parse(savedCache);
    Object.entries(parsed).forEach(([key, value]) => {
      imageCache.set(key, value as CacheEntry);
    });
  }
} catch (error) {
  console.warn('Failed to load image cache:', error);
}

// Save cache to localStorage
const saveCache = () => {
  try {
    const cacheObj = Object.fromEntries(imageCache.entries());
    localStorage.setItem('campImageCache', JSON.stringify(cacheObj));
  } catch (error) {
    console.warn('Failed to save image cache:', error);
  }
};

// Clean expired entries
const cleanCache = () => {
  const now = Date.now();
  let hasChanges = false;
  
  imageCache.forEach((entry, key) => {
    if (now - entry.timestamp > CACHE_DURATION) {
      imageCache.delete(key);
      hasChanges = true;
    }
  });

  if (hasChanges) {
    saveCache();
  }
};

// Default camp images for fallback
const defaultImages = [
  'https://images.unsplash.com/photo-1472898965229-f9b06b9c9bbe?auto=format&fit=crop&w=800&q=80',
  'https://images.unsplash.com/photo-1475721027785-f74eccf877e2?auto=format&fit=crop&w=800&q=80',
  'https://images.unsplash.com/photo-1464692805480-a69dfaafdb0d?auto=format&fit=crop&w=800&q=80',
  'https://images.unsplash.com/photo-1470074292515-19fdd1d6d8aa?auto=format&fit=crop&w=800&q=80',
  'https://images.unsplash.com/photo-1486128105845-91daff43f404?auto=format&fit=crop&w=800&q=80'
];

export function getCampImage(camp: { name: string }): string {
  const cacheKey = `camp-${camp.name}`;
  
  // Clean expired entries periodically
  cleanCache();
  
  // Check cache first
  const cached = imageCache.get(cacheKey);
  if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
    return cached.url;
  }

  // Get a random default image
  const imageUrl = getDefaultCampImage();
  
  // Cache the result
  imageCache.set(cacheKey, {
    url: imageUrl,
    timestamp: Date.now()
  });
  saveCache();
  
  return imageUrl;
}

export function getDefaultCampImage(): string {
  const randomIndex = Math.floor(Math.random() * defaultImages.length);
  return defaultImages[randomIndex];
}